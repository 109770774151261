import { UserNavModel } from "../models/user-nav-model";
import { getLoggedInUserData } from "./columbus-service";

// eslint-disable-next-line
export const validateUser = async (page: string) => {
  let navDetails: UserNavModel = await getLoggedInUserData();
  let redirectUrl = ``;
  console.log(page);
  if (!navDetails.menuPathList.includes("/" + page)) {
    redirectUrl = navDetails.menuPathList[0];
    const origin = `${window.location.origin}`;
    window.location.href = origin.concat(redirectUrl);
  }
};
